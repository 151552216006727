import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/semantic/Layout"
import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";
import {Container, Responsive, Icon} from "semantic-ui-react";
import styled from 'styled-components';


const PageNumberWrapper = styled.div`
    border: 1px solid #EEEEEE;
    padding: 8px 16px;
    background: ${props => props.isCurrentPage ? '#eee' : 'white'}
`

const getWidth = () => {
  const isSSR = typeof window === 'undefined' 
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
    const nextPage = `/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div style={{display:'flex'}}>
        {!isFirstPage && <PageNumberWrapper><Link to={prevPage}><Icon name="arrow circle left" /></Link></PageNumberWrapper>}
        {[...Array(pageCount)].map((_val, index) => {
          const pageNum = index + 1;
          return (
            <PageNumberWrapper isCurrentPage={pageNum === currentPageNum}>
            <Link
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? "/" : `/${pageNum}/`}
            >
              {pageNum}
            </Link>
            </PageNumberWrapper>
          );
        })}
        {!isLastPage && <PageNumberWrapper><Link to={nextPage}><Icon name="arrow circle right" /></Link></PageNumberWrapper>}
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
            <Helmet title={config.siteTitle} />
   
        <div style={{backgroundColor:'white'}}>
          <Responsive getWidth={getWidth} >        
            <Container style={{ minHeight: 50, padding: '1em 0em', backgroundColor:'#FFFFFF' }}>
              <PostListing postEdges={postEdges} />
              {console.log(postEdges)}
              {this.renderPaging()}
            </Container>          
          </Responsive>
  
  
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date(formatString: "MMM Do, YYYY") 
          }
          excerpt(pruneLength: 500)
          timeToRead
          frontmatter {
            title
            tags
            cover
            author
            date(formatString: "MMM Do YYYY") 
            summary
          }
        }
      }
    }
  }
`;
